import { PermalinkMap } from '../common/components/Links';

const atsnFiling =
  'https://www.sec.gov/Archives/edgar/data/1692652/000169265225000003/';
const atsnLinks = {
  atsn: `${atsnFiling}xslATS-N_X01/primary_doc.xml`,
  atsnPart2Item7: `${atsnFiling}xslATS-N_X01/primary_doc.xml#partIIitem7`,
  atsnMktData: `${atsnFiling}xslATS-N_X01/primary_doc.xml#partIIIitem23`,
  atsnPart3Item7: `${atsnFiling}xslATS-N_X01/primary_doc.xml#partIIIitem7`,
  atsnPart3Item11: `${atsnFiling}xslATS-N_X01/primary_doc.xml#partIIIitem11`,
};

const legalLinks = {
  bcp: '/legal/bcp/',
  privacyPolicy:
    'https://app.termly.io/policy-viewer/policy.html?policyUUID=cb5783c6-cbf9-462b-9f63-2ac6466baf7f',
};

const externalLink = {
  smartMarket: 'https://en.wikipedia.org/wiki/Smart_market',
  nobelPressRelease:
    'https://www.nobelprize.org/prizes/economic-sciences/2020/press-release/',
  nobelDetail:
    'https://www.nobelprize.org/uploads/2020/09/popular-economicsciencesprize2020.pdf',
};

/* Global static links shared by all site content */
export const sharedLinks: PermalinkMap = {
  ...atsnLinks,
  ...legalLinks,
  ...externalLink,
};
